<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-3">
          <b-form-input debounce="1000" placeholder="Введите поисковый запрос" v-model="search_string"/>
        </div>
        <div class="col-3">
          <b-form-select label="Тип курьера" v-model="type" :options="type_carrier">
          </b-form-select>
        </div>
        <div class="col-3">
          <b-form-select label="Статус курьера" v-model="status" :options="status_carrier">
          </b-form-select>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table
              @change-page="pagination"
              @change-sort="sorting"
              :table-data="tableData"
              title="Список курьеров"
              :pageCount="pageCount"
              :perPage="perPage"
              :currentPage="filter.page"
              @call-modal="(e, nick) => {
                salary_id = e
                salary_nickname = nick
                salary_modal = !salary_modal
              }"
          ></projects-table>
        </div>
      </div>
    </div>
    <modal :show.sync="salary_modal">
      <h3 slot="header" class="modal-title">Выберите период расчёта з/п курьера {{salary_nickname}}</h3>
      <div class="d-flex justify-content-between">
        <date-picker type="date"
                     valueType="format"
                     v-model="salary_range"
                     range
                     :formatter="momentFormat"
                     lang="ru"/>
        <b-button variant="success" @click="getSalary">Выбрать</b-button>
      </div>
      <div v-if="salary_data" class="mt-3">
        <p>Сумма наличных у курьера: {{salary_data.on_hand}} руб</p>
        <p>Доля курьера из наличных: {{salary_data.cod_salary}} руб</p>
        <p>Доля курьера с безналичных заказов: {{salary_data.no_cash_salary}} руб</p>
        <p>Итоговая з/п курьера за период: {{salary_data.all_salary}} руб</p>
      </div>
    </modal>
  </div>
</template>

<script>
import ProjectsTable from "@/views/Tables/CarriersTable";
import {BFormSelect, BFormInput, BButton} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment'

const classic_date_format = 'DD.MM.YYYY'
export default {
  name: "CarrierList",
  components: {
    ProjectsTable,
    BFormSelect,
    BFormInput,
    DatePicker,
    BButton
  },
  data() {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format(classic_date_format) : ''
        },
        parse: (value) => {
          return value ? moment(value, classic_date_format).toDate() : null
        }
      },
      salary_data: null,
      salary_range: null,
      salary_modal: false,
      salary_id: null,
      salary_nickname: null,
      perPage: 10,
      pageCount: 1,
      tableData: [],
      type: null,
      status: null,
      search_string: null,
      filter: {
        search: null,
        search_column: ['ID', 'user_login', 'user_nicename', 'user_email', 'url'],
        meta_key: [],
        meta_value: [],
        meta_compare: [],
        per_page: 10,
        order_by: '',
        order: 'asc',
        role: ['carrier']
      },
      type_carrier: [
        {value: null, text: 'Выберите тип курьера'},
        {value: 'Пешком', text: 'Пешком'},
        {value: 'Легковой автомобиль', text: 'Легковой автомобиль'},
        {value: 'Грузовой автомобиль', text: 'Грузовой автомобиль'},

      ],
      status_carrier: [
        {value: null, text: 'Выберите статус курьера'},
        {value: '1', text: 'Подтвержден'},
        {value: '0', text: 'Не подтвержден'},
      ]
    }
  },
  methods: {
    getSalary() {
      //todo data is here
      const dates_perform = this.salary_range.map(e => moment(e, classic_date_format).format('YYYY-MM-DD')).join('...')
      this.$http.get('carriercalc/v1/carrier-salary', {
        params: {
          carrier_id: this.salary_id,
          modify_date: dates_perform
        }
      }).then(res => {
        this.salary_data = res.data
      })
    },
    sorting(field, type) {
      this.filter.order_by = field
      this.filter.order = type
      this.getCarriersList()
    },
    pagination(item) {
      this.filter.page = item
      this.getCarriersList()
    },
    getCarriersList() {
      this.$http.get('carriercalc/v1/users-list', {
        params: this.filter
      }).then(res => {
        this.tableData = res.data.users
        this.pageCount = res.data.last_page
        this.perPage = Number(res.data.per_page)
      })
    },
  },
  watch: {
    salary_modal(n) {
      if (!n) {
        this.salary_id = null
        this.salary_data = null
      }
    },
    search_string(n) {
      this.filter.search = n
      this.getCarriersList()

    },
    type(n, old) {
      let index = this.filter.meta_key.indexOf('carrier_type'),
          type = this.filter.meta_value.indexOf(old)
      if (index > -1) {
        this.filter.meta_key.splice(index, 1);
        this.filter.meta_value.splice(type, 1);
        this.filter.meta_compare.splice(1, 1);
      }
      if (n) {
        this.filter.meta_key.push('carrier_type')
        this.filter.meta_value.push(n)
        this.filter.meta_compare.push('=')
      }

      this.getCarriersList()
    },
    status(n, old) {
      let index = this.filter.meta_key.indexOf('user_confirm'),
          type = this.filter.meta_value.indexOf(old)
      if (index > -1) {
        this.filter.meta_key.splice(index, 1);
        this.filter.meta_value.splice(type, 1);
        this.filter.meta_compare.splice(1, 1);
      }
      if (n) {
        this.filter.meta_key.push('user_confirm')
        this.filter.meta_value.push(n)
        this.filter.meta_compare.push('=')
      }

      this.getCarriersList()
    }
  },
  created() {
    this.getCarriersList()
    // this.$notify('Новое оповещение')
  }
}
</script>

