<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :thead-classes="'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>ID курьера</th>
          <th>
            <span class="cursor-pointer"
                  :class="current_sort == 'last_name' ? 'active-sort' : ''"
                  @click="changeSort('last_name')"
                  title="Нажмите, чтобы сортировать">Имя
            <i class="fa"
               v-if="current_sort == 'last_name'"
               :class="current_sort == 'last_name' && sort_direction == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
            </span>
          </th>
          <th><span class="cursor-pointer"
                    :class="current_sort == 'user_email' ? 'active-sort' : ''"
                    @click="changeSort('user_email')"
                    title="Нажмите, чтобы сортировать">Email
          <i class="fa"
             v-if="current_sort == 'user_email'"
             :class="current_sort == 'user_email' && sort_direction == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
          </span></th>
          <th>
            <span class="cursor-pointer"
                  :class="current_sort == 'carrier_type' ? 'active-sort' : ''"
                  @click="changeSort('carrier_type')"
                  title="Нажмите, чтобы сортировать">Тип
          <i class="fa"
             v-if="current_sort == 'carrier_type'"
             :class="current_sort == 'carrier_type' && sort_direction == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
          </span></th>
          <th>Статус</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              {{ row.data.ID }}
            </div>
          </th>

          <td>
            {{ row.data.display_name }}
          </td>
          <td>
            {{ row.data.user_email }}
          </td>
          <td class="badges-area">
            <badge v-if="row.data.meta.carrier_type" rounded type="primary">
              <span>{{ row.data.meta.carrier_type }}</span>
            </badge>
          </td>

          <td class="badges-area">
            <badge v-if="!Number(row.data.meta.user_confirm)" rounded type="warning">
              <span>Не подтвержден</span>
            </badge>
            <badge v-if="Number(row.data.meta.working)" rounded type="success">
              <span>Сейчас работает</span>
            </badge>
          </td>


          <td class="text-right">
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" @click.stop="$router.push('/carrier-form/'+row.ID)">Изменить</a>
                <a class="dropdown-item" @click.stop="$emit('call-modal', row.ID, row.data.display_name )">Отчёт по зарплате</a>
                <!--                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>-->
              </template>
            </base-dropdown>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end">
      <base-pagination :value="currentPage"
                       @input="changePage"
                       :page-count="pageCount"
                       :per-page="perPage"/>
    </div>

  </div>
</template>
<script>
export default {
  name: 'carrier-table',
  props: {
    title: String,
    tableData: {
      type: Array,
      default: () => []
    },
    pageCount: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      sort_direction: 'asc',
      current_sort: null
    }
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', page)
    },
    changeSort(field) {
      if (field == this.current_sort) {
        this.sort_direction = this.sort_direction == 'asc' ? 'desc' : 'asc'
      }
      this.current_sort = field
      this.$emit('change-sort', field, this.sort_direction)

    }

  }
}
</script>
<style>
</style>
